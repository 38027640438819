import React from 'react';
import { noDataVideo } from '../utils/constants';

const ReelMedia = ({ data, muted }) => {
  const media = data?.content?.url;
  const isVideo = data?.content?.type === 'video';

  const backgroundImg =
    'https://firebasestorage.googleapis.com/v0/b/lifestyle-prod-400900.appspot.com/o/public%20files%2Fsplash.png?alt=media&token=31e1dc8b-b013-43c2-89ef-6a9ec36b9a6a';
  return (
    <div
      style={{
        position: 'absolute',
        height: '100%',
        width: '100%',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: isVideo ? `url(${backgroundImg})` : `url(${media})`,
          backgroundSize: 'cover',
          filter: 'blur(20px)', // Apply blur filter only to the background
          zIndex: -1, // Move the background behind other elements
        }}
      />
      {isVideo && data && (
        <video
          autoPlay
          loop
          playsInline
          muted={muted}
          style={{ objectFit: 'cover', height: '100%', width: '100%' }}
          poster={backgroundImg}
          src={media}
        />
      )}

      {!isVideo && data && (
        <img src={media} alt="event" style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
      )}

      {!data && (
        <video
          autoPlay
          loop
          playsInline
          muted={muted}
          style={{ objectFit: 'cover', height: '100%', width: '100%' }}
          poster={backgroundImg}
          src={noDataVideo}
        />
      )}
    </div>
  );
};

export default ReelMedia;
