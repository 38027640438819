import './App.css';
import { useEffect, useState } from 'react';
import CustomCard from './components/CustomCard/CustomCard';
import useGetEvent from './hooks/useGetEvent';
// import logo from public file in public folder called logo.png
import logo from './assets/logo.png';
function App() {
  const [event, setEvent] = useState(null);
  const url = window.location.href;
  const eventId = url.split('e=')[1];
  const userAgent = navigator.userAgent;
  const isAndroid = userAgent.includes('Android');
  const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.oppong.lifestyleapp';
  const appStoreUrl = 'https://apps.apple.com/ca/app/lifestyle-experiences/id6468594941';
  const { data } = useGetEvent(eventId);

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const isInstagram = /Instagram/i.test(navigator.userAgent);
  const storeUrl = isAndroid ? playStoreUrl : appStoreUrl;

  useEffect(() => {
    if (eventId && !isInstagram) {
      setEvent(eventId);
      window.location = `lifestyleapp://?e=${event}`;
    } else if (isMobile && !isInstagram) {
      setTimeout(function () {
        window.location = isMobile ? storeUrl : '';
      }, 1000);
    }
  }, [eventId, isMobile, isInstagram, storeUrl, event]);

  return (
    <div
      className="App"
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
    >
      <img src={logo} alt="logo" style={{ width: 100 }} />

      <CustomCard data={data} eventId={eventId} />
    </div>
  );
}

export default App;
