import React from 'react';
import GlowingBorderButton from './GlowingBorderButton';
import { Button } from 'react-daisyui';
const GetTicketsButton = (props) => {
  const item = props?.item;
  const id = item?.id;
  const userAgent = navigator.userAgent;
  const isAndroid = userAgent.includes('Android');
  const playStoreUrl = 'https://play.google.com/store/apps/details?id=com.oppong.lifestyleapp';
  const appStoreUrl = 'https://apps.apple.com/ca/app/lifestyle-experiences/id6468594941';

  const openInApp = () => {
    // if app not installed, check if android or ios and redirect to app store

    setTimeout(function () {
      window.open(`lifestyleapp://?e=${id}`, '_blank');
      // open app if installed else redirect to app store
    }, 25);

    isAndroid ? window.open(playStoreUrl, '_blank') : window.open(appStoreUrl, '_blank');
  };

  return (
    <button onClick={openInApp}>
      <GlowingBorderButton>
        <Button
          variant="link"
          style={{
            width: '100%',
            backgroundColor: 'rgba(0,0,0,0)',
            color: 'white',
            textDecoration: 'none',
            // animate the text color
            transition: 'color 0.5s ease-in-out',
          }}
        >
          Get Tickets In App!
        </Button>
      </GlowingBorderButton>
    </button>
  );
};

export default GetTicketsButton;
