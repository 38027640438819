export const myColors = {
  primary: '#5a8ec7',
  primaryz: '#4166F5',
  darkGray: '#4d4d4d',
  lightGray: '#f2f2f2',
  mediumGray: '#a6a6a6',
  green: '#2dc653',
  red: '#c1121f',
  dustyRose: '#CB857C',
  scallopSeashell: '#F6CBB7',
  blue: '#4166F5',
  lightBlue: '#cfd8fc',
  white: '#ffffff',
  black: '#090909',
  bottomSheetBackground: '#1a1a1a',
  starYellow: '#FFD700',
  transparentBG: 'rgba(0,0,0,0.2)',
};
