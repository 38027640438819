import React from 'react';
import GlowingBorderButton from './GlowingBorderButton';
import { Button } from 'react-daisyui';

const BookNowButton = (props) => {
  const item = props?.item;

  return (
    <button
      onClick={() => {
        window.open(item.attachedLink, '_blank');
      }}
    >
      <GlowingBorderButton>
        <Button
          variant="link"
          style={{
            backgroundColor: 'rgba(0,0,0,0)',
            color: 'white',
            textDecoration: 'none',
            // animate the text color
            transition: 'color 0.5s ease-in-out',
          }}
        >
          Grab Your Spot!
        </Button>
      </GlowingBorderButton>
    </button>
  );
};

export default BookNowButton;
