import React, { useState } from 'react';
import { Button, Modal } from 'react-daisyui';
import InfoIcon from '@mui/icons-material/Info';

const InfoButton = (props) => {
  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    setVisible(!visible);
  };

  const openSite = () => {
    window.open('https://pro.lifestyleapp.co', '_blank');
  };
  return (
    <div className="font-sans">
      <InfoIcon onClick={toggleVisible} style={{ color: 'white', cursor: 'pointer', marginLeft: '10px' }} />

      <Modal.Legacy {...props} open={visible} onClickBackdrop={toggleVisible}>
        <Modal.Header style={{ color: 'lightgray', fontWeight: 'bold' }}>
          How do I get my business or event featured?
        </Modal.Header>
        <Modal.Body style={{ color: 'lightgray', fontSize: 12 }}>
          We can help you reach more people in your area. Click below to learn more.,
        </Modal.Body>
        <Modal.Actions>
          <form method="dialog">
            <Button as="a" href="https://pro.lifestyleapp.co" target="_blank" onClick={openSite}>
              Learn More
            </Button>
          </form>
        </Modal.Actions>
      </Modal.Legacy>
    </div>
  );
};

export default InfoButton;
