import React from 'react';
import { makeStyles } from '@mui/styles';
import { myColors } from '../styles/theme';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',

    borderWidth: 0,
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderStyle: 'solid',
    transition: 'border-color 0.5s ease-in-out',
    backgroundColor: 'rgba(0,0,0,0.1)',
  },
  glowBorder: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderWidth: 2,
    borderRadius: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderStyle: 'solid',
    borderColor: 'transparent',
    animation: '$glowEffect 1s infinite alternate',
  },
  '@keyframes glowEffect': {
    '0%': {
      borderColor: myColors.primary,
    },
    '100%': {
      borderColor: 'inherit',
    },
  },
}));

const GlowingBorderButton = ({ children, borderColor = myColors.primary, margin, ...props }) => {
  const classes = useStyles();

  return (
    <div className={classes.container} style={{ marginTop: 8 }}>
      <div className={classes.glowBorder} style={{ borderColor: borderColor || 'currentColor' }} />
      {children}
    </div>
  );
};

export default GlowingBorderButton;
