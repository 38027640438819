import React from 'react';
import { firebaseConfig } from '../utils/config';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';

const MyMaps = ({ data }) => {
  const location = data.location;
  const position = { lat: location.latitude, lng: location.longitude };
  const formatted_address = location.geocode[0].formatted_address;

  const handleClick = () => {
    const openInGoogleMaps = window.confirm('Do you want to open this location in Google Maps?');

    if (openInGoogleMaps) {
      window.open(`https://www.google.com/maps/search/?api=1&query=${formatted_address}`, '_blank');
    }
  };
  return (
    <APIProvider apiKey={firebaseConfig.mapsApi}>
      <div style={{ width: '80%', height: '300px', marginBottom: 20, borderRadius: 20 }}>
        <Map defaultCenter={position} defaultZoom={10} style={{ borderRadius: 20 }} onClick={handleClick}>
          <Marker position={position} />
        </Map>
      </div>
    </APIProvider>
  );
};

export default React.memo(MyMaps);
