import * as React from 'react';
import { useState } from 'react';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';

import { Typography } from '@mui/joy';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import PlaceIcon from '@mui/icons-material/Place';
import { myColors } from '../../styles/theme';
import ReelMedia from '../ReelMedia';
import InfoButton from '../InfoButton';
import GetTicketsOrBookNow from '../GetTicketsOrBookNow';
import MyBottomSheet from '../MyBottomSheet';
const CustomCard = (props) => {
  const { data, eventId } = props;
  const [open, setOpen] = useState(false);

  const [muted, setMuted] = useState(true);

  return (
    // make this card look like a reel
    <div className="flex items-center justify-center">
      <Card component="li" sx={{ width: 300, height: 600, marginTop: '2%' }}>
        <CardCover>
          <ReelMedia data={data} muted={muted} />
        </CardCover>
        {data && (
          <CardContent>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginLeft: 'auto',
              }}
            >
              {!muted ? (
                <VolumeOffIcon
                  onClick={() => setMuted(!muted)}
                  style={{
                    color: 'white',
                    cursor: 'pointer',
                  }}
                />
              ) : (
                <VolumeUpIcon
                  onClick={() => setMuted(!muted)}
                  style={{
                    color: 'white',
                    cursor: 'pointer',
                  }}
                />
              )}
              {/* <InfoIcon style={{ color: 'white', cursor: 'pointer', marginLeft: '10px' }} /> */}
              <InfoButton data={data} />
            </div>

            <div
              onClick={() => setOpen(true)}
              style={{
                // backgroundColor: 'green',
                marginTop: 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
              }}
            >
              <Typography variant="plain" sx={{ color: 'white', fontWeight: 'bold', fontSize: 16 }}>
                {data?.title}
              </Typography>
              <Typography variant="body2" sx={{ color: 'white', fontSize: 13 }}>
                {data?.description.substring(0, 30) + '...'}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: 'white', fontWeight: '100', fontSize: 13, display: 'flex', alignItems: 'center' }}
              >
                <PlaceIcon style={{ color: myColors.primary }} />
                {data?.location.geocode[0].formatted_address.substring(0, 30) + '...'}
              </Typography>

              <GetTicketsOrBookNow item={data} eventId={eventId} />
            </div>
          </CardContent>
        )}
      </Card>
      {open && <MyBottomSheet open={open} setOpen={setOpen} data={data} close={() => setOpen(false)} />}
    </div>
  );
};

export default CustomCard;
