import { Typography } from '@mui/joy';
import React from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import GetTicketsOrBookNow from './GetTicketsOrBookNow';
import { format } from 'date-fns';
import LinkIcon from '@mui/icons-material/Link';
import MyMaps from './MyMaps';
import { Button } from 'react-daisyui';
import { ArrowDownward } from '@mui/icons-material';

const MyBottomSheet = (props) => {
  const { open, setOpen, data } = props;
  const hasStartDate = data.schedule.type === 'single';
  console.log(data);

  return (
    <div>
      <BottomSheet
        open={open}
        onDismiss={() => setOpen(false)}
        style={{}}
        snapPoints={({ maxHeight }) => [maxHeight * 0.6, maxHeight * 0.8]}
      >
        <div style={{ padding: '20px', width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Button
              onClick={() => setOpen(false)}
              style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}
            >
              <ArrowDownward />
            </Button>
            <Typography
              sx={{ fontSize: 40, color: 'lightgray', marginBottom: 2, justifyContent: 'center', alignItems: 'center' }}
            >
              {data.title}
            </Typography>
            <Typography
              sx={{ fontSize: 20, color: 'lightgray', marginBottom: 2, justifyContent: 'center', alignItems: 'center' }}
            >
              {data.description}
            </Typography>

            {hasStartDate && (
              <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 5 }}>
                <Typography sx={{ fontSize: 15, color: 'lightgray' }}>
                  Start: {format(new Date(data.schedule.date), 'eeee dd, yyyy  hh:mm a')}
                </Typography>

                <Typography sx={{ fontSize: 15, color: 'lightgray' }}>
                  End: {format(new Date(data.schedule.endDate), 'eeee dd, yyyy hh:mm a')}
                </Typography>
              </div>
            )}

            <Button
              variant="link"
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 10,
                marginTop: 10,
                textDecoration: 'none',
              }}
            >
              <LinkIcon />

              <Typography sx={{ fontSize: 20, color: 'lightgray' }}>{' Website'}</Typography>
            </Button>

            <Typography
              sx={{
                fontSize: 20,
                color: 'lightgray',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {data.location.geocode[0].formatted_address}
            </Typography>

            <MyMaps data={data} />

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <GetTicketsOrBookNow item={data} />
            </div>
          </div>
        </div>
      </BottomSheet>
    </div>
  );
};

export default MyBottomSheet;
