import React from 'react';

import GetTicketsButton from './GetTicketsButton';
import BookNowButton from './BookNowButton';

const GetTicketsOrBookNow = (props) => {
  const { item, closeModal, modal, eventId } = props;
  const tickets = item.tickets && item.tickets.length > 0;
  return (
    <>
      {tickets ? (
        <GetTicketsButton item={item} closeModal={closeModal} modal={modal} eventId={eventId} />
      ) : (
        <BookNowButton item={item} />
      )}
    </>
  );
};
export default GetTicketsOrBookNow;
